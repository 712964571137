import './UserMenu.css';
import React from 'react';
import storage from "../storage";
import i18n from '../i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import request from "../request";
import {Link} from "react-router-dom";

class UserMenu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      seller_mode: storage.me.seller_mode,
      seller_price_mode: storage.me.seller_price_mode,
      seller_price_percentage: storage.me.seller_price_percentage,
      show_response: false,
    };

    this.close = this.close.bind(this);
    this.save = this.save.bind(this);
  }

  componentDidMount() {
    this.clearStorageOnChangeIndex = storage.onChange((key) => {
      if (key === 'me') {
        this.setState({
          ...this.state,
          seller_mode: storage.me.seller_mode,
          seller_price_mode: storage.me.seller_price_mode,
          seller_price_percentage: storage.me.seller_price_percentage,
        });
      }
    })
    this.props.attachListenerOnOpen(() => {
      this.setState({
        ...this.state,
        open: true,
      });
    });

    this.props.attachListenerOnClose(() => {
      this.setState({
        ...this.state,
        open: false,
      });
    });
  }

  componentWillUnmount() {
    storage.clearOnChange(this.clearStorageOnChangeIndex);
  }

  close() {
    this.setState({
      ...this.state,
      open: false,
    });
  }

  save() {
    request.post('api/update-seller-mode', {
      ...this.state
    }).then( (response) => {
      storage.set('me', {
        ...storage.me,
        ...this.state,
      })
      request.get_company_data();
      this.setState({
        ...this.state,
        show_response: true,
      }, () => {
        setTimeout(() => {
          this.setState({
            ...this.state,
            show_response: false,
          })
        }, 2000)
      })
    });
  }

  handleSellerMode(elem, value) {
    this.setState({
      ...this.state,
      [elem]: value
    })
  }

  render() {
    return (
      <div className={`basket ${this.state.open ? 'open' : ''}`}>
        <div
          className="backdrop"
          onClick={this.close}
        ></div>
        <div className="container">
          <div className="header">
            <h3>{i18n.t('seller_menu')}</h3>
            <FontAwesomeIcon
                icon={faTimes}
                className="btn-close"
                onClick={this.close}
            />
          </div>

          <div className="w-100">
            <h4 className='d-flex flex-direction-column align-items-center justify-content-center'>{i18n.t('seller_menu_prices')}</h4>

            <div className="form-group">
              <span>{i18n.t('hide_prices')}</span>
              <input type="checkbox"
                     onChange={(e) => {
                       this.handleSellerMode('seller_mode', e.target.checked)
                     }}
                     checked={this.state.seller_mode}
              />
            </div>
            <div className="form-group">
              <span>{i18n.t('adjust_prices')}</span>
              <input type="checkbox"
                     onChange={(e) => {
                       this.handleSellerMode('seller_price_mode', e.target.checked)
                     }}
                     checked={this.state.seller_price_mode}
              />
            </div>
            {
                this.state.seller_price_mode &&
                <div className="form-group">
                  <span>{i18n.t('price_increase_percent')}</span>
                  <input type="number" max="100"
                         onChange={(e) => {
                           this.handleSellerMode('seller_price_percentage', e.target.value)
                         }}
                         value={this.state.seller_price_percentage}
                  />
                </div>
            }

            <div className="form-group">
              <button className="btn-save"
                      onClick={this.save}
              >
                {i18n.t('save_changes')}
              </button>
            </div>
            {
                this.state.show_response &&
                <div className="form-group">
                  <div className="alert alert-success w-100">{i18n.t('seller_mode_updated')}</div>
                </div>
            }

          </div>

          <div className="w-100">
            <div className="form-group d-flex flex-direction-column">
              <h4>{i18n.t('seller_menu_access_tokens')}</h4>
              <p>{i18n.t('seller_menu_access_tokens_info')}</p>
            </div>
            <div className="form-group">
              <Link className="btn-save"
                    onClick={this.close}
                    to='/account/access_tokens'
              >
                {i18n.t('go_to_access_tokens')}
              </Link>
            </div>
          </div>


          <div className="controls">

          </div>
        </div>
      </div>
    );
  }
}

export default UserMenu;