import './AccountPage.css';
import React from 'react';
import i18n from '../i18n';
import Modal from '../modal/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleRight,
  faLock,
  faHome,
  faBox,
  faLink,
  faEye,
  faCheck,
  faAngleLeft,
  faDatabase,
  faPlus,
  faCopy,
  faTrash
} from '@fortawesome/free-solid-svg-icons';
import { faUser, faHeart, faTimesCircle} from '@fortawesome/free-regular-svg-icons';
import { } from '@fortawesome/free-brands-svg-icons';
import history from '../history';
import request from '../request';
import utils from '../Utils';
import {
  Link, useParams,
} from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import storage from '../storage';
import DatePicker from "react-date-picker";
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';

class AccountPage extends React.Component {

  constructor(props) {
    super(props);

    const params = this.props.match.params;

    this.state = {
      checkingSignedInStatus: true,
      activeTab: params.tab ?? 'profile',
      personalDataForm: {
        firstname: '',
        lastname: '',
        email: '',
        cell: '',
        phone: '',
      },
      xml_token: "",
      countries: null,
      regions: null,
      // update message
      messageStatusUpdate: false,
      messageTypeUpdate: '',
      messageUpdate: '',
      passwordForm: {
        oldPassword: '',
        newPassword:'',
        confirmPassword: '',
      },
      addressData: [],
      addressDataForm: {
        address: '',
        number: '1',
        floor: '1 όροφος',
        region: 'martiou',
        city: 'thessaloniki',
        country: 'ΕΛΛΑΣ',
        zip: '12345',
        notes: 'Σημειώσεις',
        phone: '2101234567',
        type: '1',
      },
      default_address: 1,
      countAddresses: 0,
      isDefaultAddress: false,
      isCheckedAddressBox: false,
      modalStatus: false,
      message:'',
      messageStatus: false,
      showDeleteAddressModal: false,
      showNewAddressAddedModal: false,
      showUpdateModal: false,
      showOrderModal: false,
      expiration_date: new Date(),
      showLinkModal: false,
      orderModalData: {},
      deliveryTypeSelectedOptions: [],
      selectedOption: '1',
      items: [],
      isLoaded: false,
      itemArray:[],
      ordersData: {},
      ordersPages: 0,
      orders_page: 1,
      editAddress: {
        address:"",
        city:"",
        country:"",
        floor:"",
        id:"",
        notes:"",
        number:"",
        region:"",
        status:"",
        type:"",
        zip:"",
      },
      deleteAddress:{
        address:"",
        city:"",
        country:"",
        floor:"",
        id:"",
        notes:"",
        number:"",
        region:"",
        status:"",
        type:"",
        zip:"",
      },
      access_links: [],
      /*
      - Λογότυπο
      - E-mail επικοινωνίας
      - Τηλέφωνο επικοινωνίας
      - Διεύθυνση καταστήματος
      - Brand name
      */
      user_brand: {
        email: '',
        address: '',
        brand_name: '',
        city: '',
        zip: '',
        gemi: '',
        phone: '',
        logo: '',
      }
    };

    // bind 'this' to functions
    this.changeActiveTab = this.changeActiveTab.bind(this);
    this.submitPassword = this.submitPassword.bind(this);
    this.onPasswordChange = this.onPasswordChange.bind(this);
    this.showMessage = this.showMessage.bind(this);
    this.addAddress = this.addAddress.bind(this);
    this.getAddresses = this.getAddresses.bind(this);
    this.deleteAddress = this.deleteAddress.bind(this);
    this.updatePersonalDetail = this.updatePersonalDetail.bind(this);
    this.timer = this.timer.bind(this);
    this.isActiveAddress = this.isActiveAddress.bind(this);
    this.showEditModal = this.showEditModal.bind(this);
    this.handleCheckBox = this.handleCheckBox.bind(this);
    this.onInputChangeUpdateAddress = this.onInputChangeUpdateAddress.bind(this);
    this.showDeleteAddressModal = this.showDeleteAddressModal.bind(this);
    this.updateAddress = this.updateAddress.bind(this);
    this.setExpirationDate = this.setExpirationDate.bind(this);
    this.saveAccessToken = this.saveAccessToken.bind(this);
    this.deleteLink = this.deleteLink.bind(this);
    this.getUserBrand = this.getUserBrand.bind(this);
    this.setUserBrand = this.setUserBrand.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.removeLogo = this.removeLogo.bind(this);
  }
  // end construct

  componentDidUpdate(prevProps, prevState) {
    document.title = i18n.t('account_page_title');
  }

  getLookups(){
    request.get(`api/signup_lookups`).then((response) => {
      if(!request.response_valid(response)){
        return;
      }
      if (response.status) {
        this.setState({
          ...this.state,
          countries: response.data.data.countries,
          regions: response.data.data.regions,
        })
      }
    });
  }

  getUserBrand() {
    request.get(`api/get-user-brand`).then((response) => {
      if (response.data) {

        this.setState({
          ...this.state,
          user_brand: {
            email: response.data.data.email,
            address: response.data.data.address,
            brand_name: response.data.data.brand_name,
            city: response.data.data.city,
            zip: response.data.data.zip,
            gemi: response.data.data.gemi,
            phone: response.data.data.phone,
            logo: response.data.data.logo,
            logo_preview: response.data.data.logo ?? null,
          }
        });
      }
    });
  }

  setUserBrand() {
    request.post(`api/set-user-brand`, {
      ...this.state.user_brand
    }).then((response) => {
      if (response.data) {
        window.alert(i18n.t('changes_saved'))
      }
    });
  }

  handleFileChange(event)
  {
    const selectedFile = event.target.files[0];

    // Check if a file is selected
    if (!selectedFile) return;

    // Validate file type
    if (!selectedFile.type.startsWith("image/")) {
      this.throwError("Only image files are allowed.");
      return;
    }

    // Validate file size (1MB = 1 * 1024 * 1024 bytes)
    if (selectedFile.size > 1 * 1024 * 1024) {
      this.throwError("File size must not exceed 1MB.");
      return;
    }

    const reader = new FileReader();
    reader.onloadend = () => {
      this.setState({
        ...this.state,
        user_brand: {
          ...this.state.user_brand,
          logo: reader.result,
          logo_preview: URL.createObjectURL(selectedFile)
        }
      });
    };
    reader.readAsDataURL(selectedFile);
  }

  throwError(str) {
    window.alert(str)
  }

  removeLogo() {
    this.setState({
      ...this.state,
      user_brand: {
        ...this.state.user_brand,
        logo: null,
        logo_preview: null,
      }
    })
  }
  onLanguageChange = () => {
    this.forceUpdate();
  }
  componentDidMount()
  {
    document.title = i18n.t('account_page_title');
    this.unlistenOnLanguageChange = i18n.onLanguageChange(() => {
      this.forceUpdate();
    });
    request.get_user_status().then((response) => {
      if (response) {
        this.updatePersonalDetail();

        this.setState({
          ...this.state,
          checkingSignedInStatus: false,
        });
      } else {
        history.push('/signin');
      }

      this.getLookups();

      this.getAddresses();

      request.get('api/order-address-type').then((response) => {
        if(!request.response_valid(response)){
          return;
        }
        this.setState({
          ...this.state,
          isloaded: true,
          deliveryTypeSelectedOptions: response.data.map((option) => {
            return {
              label: option.name,
              value: option.id,
            };
          }),
        });
      });

      this.getOrders()
      this.getAccessLinks()
      this.getUserBrand()
    });
  }
  getOrders(page = 1) {
    request.get(`api/get-orders/${page}`).then((response) => {
      if (response.data) {
        const pages = Math.ceil(response.data.orders.total / 10)
        this.setState({
          ...this.state,
          ordersData: response.data.orders,
          ordersPages: pages,
          orders_page: page
        });
      }
    });
  }

  getAccessLinks() {
    request.get(`api/get-user-access-tokens`).then((response) => {
      if (response.data) {
        this.setState({
          ...this.state,
          access_links: response.data.tokens,
        });
      }
    });
  }
  componentWillUnmount() {
    this.unlistenOnLanguageChange();
  }
  timer(str) {
    setTimeout(() => {
      this.setState({
        ...this.state,
        [str]: false
      })
    }, 3000);
  }

  getAddresses() {
    request.get('api/get-addresses').then((response) => {
      if(!request.response_valid(response)){
        return;
      }
      if (response.data.status) {
        this.setState({
          ...this.state,
          addressData: response.data.data,
          countAddresses: response.data.data.length,
          defaultAddress: response.data.address,
        });
      }

    });
  }

  updatePersonalDetail() {
    request.get('api/get-user-personal-details').then((response) => {
      if(!request.response_valid(response)){
        return;
      }
      this.setState({
        ...this.state,
        personalDataForm: {
          ...this.state.personalDataForm,
          firstname: response.data.firstname,
          lastname: response.data.lastname,
          email: response.data.email,
          cell: response.data.cell,
          phone: response.data.phone,
          // seller_mode: response.data.seller_mode,
        },
        default_address: response.data.main_address,
        xml_token: response.data.xml_token
      });
    });
  }


  // Add new address
  addAddress() {
    if (this.state.addressDataForm.address !== '' &&
        this.state.addressDataForm.region !== '' &&
        this.state.addressDataForm.city !== '' &&
        this.state.addressDataForm.country !== '' &&
        this.state.addressDataForm.zip !== '' &&
        this.state.addressDataForm.phone !== ''
    ) {
      request.post(`api/add-address`,{
        "address": this.state.addressDataForm.address,
        "region": this.state.addressDataForm.region,
        "city": this.state.addressDataForm.city,
        "country": this.state.addressDataForm.country,
        "zip": this.state.addressDataForm.zip,
        "notes": this.state.addressDataForm.notes,
        "phone": this.state.addressDataForm.phone,
      }).then( (response) => {
        // if( response.data.status === 1 ) {
        //   alert('Η νέα διεύθυνση προστέθηκε!');
        //   this.getAddresses();

        // } else {
        //   alert('Η διεύθυνση δεν προστέθηκε.');
        // }

        this.setState({
          ...this.state,
          modalStatus: false,
          showNewAddressAddedModal: true
        });
        this.getAddresses();
      });
    } else {
      alert(i18n.t("fill_all_fields"));
    }
  }

  changeActiveTab(tab) {
    this.setState({
      ...this.state,
      activeTab: tab,
    }, () => {
      history.push(`/account/${tab}`)
    });
  }

  // tab 1 perfonal data
  onTextInputChange(e, inputName) {
    this.setState({
      ...this.state,
      personalDataForm: {
        ...this.state.personalDataForm,
        [inputName]: e.target.value
      },
    });
  }

  // modal add address
  onInputChange(e, inputname) {
    this.setState({
      ...this.state,
      addressDataForm: {
        ...this.state.addressDataForm,
        [inputname]: e.target.value
      }
    })
  }

  // modal edit - update address
  onInputChangeUpdateAddress(e, inputname) {
    this.setState({
      ...this.state,
      editAddress: {
        ...this.state.editAddress,
        [inputname]: e.target.value
      }
    })
  }

  showUpadateMessage(status,type,message) {
    this.setState({
      ...this.state,
      messageStatusUpdate: true,
      messageTypeUpdate: type,
      messageUpdate: message
    })
  }

  // tab 2 change password
  onPasswordChange(e, inputName) {
    const v =  e.target.value;

    this.setState({
      ...this.state,
      passwordForm: {
        ...this.state.passwordForm,
        [inputName]: v,
      },
    })
  }

  // Start change old password
  submitPassword() {
    let oldPassword = this.state.passwordForm.oldPassword;
    let newPassword = this.state.passwordForm.newPassword;
    let confirmPassword = this.state.passwordForm.confirmPassword;
    var message = '';
    var type = '';

    // if valid password
    if (this.state.passwordForm.oldPassword !== '' &&
        this.state.passwordForm.newPassword !== '' &&
        this.state.passwordForm.confirmPassword !== ''  &&
        this.state.passwordForm.newPassword === this.state.passwordForm.confirmPassword
    )
    {
      request.post('api/update-password', { "oldPassword" : oldPassword, "newPassword" : newPassword, "confirmPassword" : confirmPassword  }).then( (response) => {
        this.showMessage(response.data.status, response.data.message);
      });
    }
    else if ( (oldPassword && newPassword && confirmPassword) === '' )
    {
      type = "alert-danger";
      message = 'Παρακαλώ συμπληρώστε όλα τα πεδία';
    }
    else if ( newPassword !== confirmPassword )
    {
      message = 'Ο νέος κωδικός δεν είναι ίδιος με τον κωδικό επιβεβαίωσης';
      type = "alert-danger";
    }

    this.setState({
      ...this.state,
      messageStatus: true,
      messageType: type,
      message: message,
    });
    this.timer('messageStatus');
  }
  // End change old password

  showMessage(status, message) {
    let type = '';

    if ( status === false) {
      type = 'alert-danger';
    } else {
      type = 'alert-success';
    }

    this.setState({
      ...this.state,
      messageType: type,
      message: message
    });
  }

  // contional render Active Address
  isActiveAddress(addressId){
    // console.log('status' + status); // ok
    if (addressId  === this.state.defaultAddress) {
      return (
          <React.Fragment>
            <FontAwesomeIcon icon={faCheck} size="10" />
            <span className="tooltiptext w-140">{i18n.t("predefined_address")}</span>
          </React.Fragment>
      );
    }
  }

  handleCheckBox(value, name) {
    this.setState({
      ...this.state,
      [name] : value,
    })
  }

  showDeleteAddressModal (id) {
    const deleteAddress = this.state.addressData.find((address) => address.id === id);

    this.setState({
      ...this.state,
      showDeleteAddressModal: true,
      deleteAddress: deleteAddress,
    });
  }

  // delete address
  deleteAddress(id) {
    request.post('api/delete-address', {
      "address_id" : id,
    }).then( (response) => {
      this.getAddresses();
    });

    this.setState({
      ...this.state,
      showDeleteAddressModal: false
    });
  }

  showEditModal(id){
    // address data from addressData
    const editAddress = this.state.addressData.find((address) => address.id === id);

    this.setState({
      ...this.state,
      showUpdateModal: true,
      editAddress: editAddress,
    });
  }


  // Update address details
  updateAddress() {

    if (this.state.editAddress.address !== '' &&
        this.state.editAddress.region !== '' &&
        this.state.editAddress.city !== '' &&
        this.state.editAddress.country !== '' &&
        this.state.editAddress.zip !== ''
    ) {
      request.post('api/update-address',{
        "id": this.state.editAddress.id,
        "address": this.state.editAddress.address,
        "region": this.state.editAddress.region,
        "city": this.state.editAddress.city,
        "country": this.state.editAddress.country,
        "zip": this.state.editAddress.zip,
        "phone": this.state.editAddress.phone,
        "notes": this.state.editAddress.notes,
      }).then( (response) => {
        // if( response.data.status === 1 ) {
        //   alert('Η νέα διεύθυνση προστέθηκε!');
        //   this.getAddresses();

        // } else {
        //   alert('Η διεύθυνση δεν προστέθηκε.');
        // }

        this.setState({
          ...this.state,
          showUpdateModal: false
        });
        this.getAddresses();
      });
    } else {
      alert('Παρακαλώ συμπληρώστε όλα τα υποχρεωτικά πεδία με *');
    }
  }

  showOrder(id) {
    const omd = this.state.ordersData.orders[id];

    this.setState({
      ...this.state,
      orderModalData: omd,
      showOrderModal: true
    })
  }

  changeOrdersPage(page) {
    this.getOrders(page)
  }

  setExpirationDate(date) {
    this.setState({
      ...this.state,
      expiration_date: date,
    })
  }

  saveAccessToken() {
    if (this.state.expiration_date == '') {
      alert('Παρακαλώ συμπληρώστε όλα τα υποχρεωτικά πεδία με *');
      return;
    }
    request.post('api/set-user-access-token',{
      expiration_date: this.state.expiration_date,
    }).then( (response) => {
      this.setState({
        ...this.state,
        showLinkModal: false,
      }, () => {
        this.getAccessLinks();
      })
    })
  }

  copyLink(link) {
    navigator.clipboard.writeText(link)
        .then(() => {
          alert('Link copied!')
        })
        .catch(() => {
          alert('Link not copied!')
        });
  }

  deleteLink(link) {
    if (window.confirm(i18n.t('delete_link_prompt'))) {
      request.get(`api/delete-user-access-token/${link}`).then(response => {
        this.getAccessLinks();
      });
    }

  }

  render() {
    return (
        <div className={`myaccount-page ${this.state.checkingSignedInStatus ? 'ninja' : ''}`}>
          <div className="tabs">
            <div
                className={`${this.state.activeTab === 'profile' ? 'active' : ''}`}
                onClick={() => this.changeActiveTab('profile')}
            >
              <div>
                <FontAwesomeIcon icon={faUser}/>
                <span>{i18n.t('profile')}</span>
              </div>
              <div>
                <FontAwesomeIcon icon={faAngleRight}/>
              </div>
            </div>
            <div
                className={`${this.state.activeTab === 'change_password' ? 'active' : ''}`}
                onClick={() => this.changeActiveTab('change_password')}
            >
              <div>
                <FontAwesomeIcon className="icon-left" icon={faLock}/>
                <span>{i18n.t('change_password')}</span>
              </div>
              <div>
                <FontAwesomeIcon icon={faAngleRight}/>
              </div>
            </div>
            <div
                className={`${this.state.activeTab === 'address' ? 'active' : ''} ${this.state.checkingSignedInStatus ? 'hidden' : ''}`}
                onClick={() => this.changeActiveTab('address')}
            >
              <div>
                <FontAwesomeIcon className="icon-left" icon={faHome}/>
                <span>{i18n.t('address')}</span>
              </div>
              <div>
                <FontAwesomeIcon icon={faAngleRight}/>
              </div>
            </div>
            <div
                className={`${this.state.activeTab === 'orders' ? 'active' : ''}`}
                onClick={() => this.changeActiveTab('orders')}
            >
              <div>
                <FontAwesomeIcon className="icon-left" icon={faBox}/>
                <span>{i18n.t('orders')}</span>
              </div>
              <div>
                <FontAwesomeIcon icon={faAngleRight}/>
              </div>
            </div>
            <div
                className={`${this.state.activeTab === 'tab_datafeed' ? 'active' : ''}`}
                onClick={() => this.changeActiveTab('tab_datafeed')}
            >
              <div>
                <FontAwesomeIcon className="icon-left" icon={faDatabase}/>
                <span>Datafeed / API</span>
              </div>
              <div>
                <FontAwesomeIcon icon={faAngleRight}/>
              </div>
            </div>
            <div
                className={`${this.state.activeTab === 'access_tokens' ? 'active' : ''}`}
                onClick={() => this.changeActiveTab('access_tokens')}
            >
              <div>
                <FontAwesomeIcon className="icon-left" icon={faLink}/>
                <span>{i18n.t('access_tokens')}</span>
              </div>
              <div>
                <FontAwesomeIcon icon={faAngleRight}/>
              </div>
            </div>
          </div>

          <div className="tabs-content">
            {/* tab 1 personal data */}
            <div className={`tab-personal-detail ${this.state.activeTab !== 'profile' ? 'hidden' : ''}`}>
              <h4>{i18n.t("personal_details")}</h4>
              <div className="container">
                <div className="w-50">
                  <span className="form-span">{i18n.t('firstname')}:</span>&nbsp;
                  <span className="form-data">{this.state.personalDataForm.firstname}</span>
                </div>

                <div className="w-50">
                  <span className="form-span">{i18n.t('lastname')}:</span>&nbsp;
                  <span className="form-data">{this.state.personalDataForm.lastname}</span>
                </div>

                <div className="w-50">
                  <span className="form-span">Email:</span>&nbsp;
                  <span className="form-data">{this.state.personalDataForm.email}</span>
                </div>

                <div className="w-50">
                  <span className="form-span">{i18n.t("cell")}:</span>&nbsp;
                  <span className="form-data">{this.state.personalDataForm.cell}</span>
                </div>

                <div className="w-50">
                  <span className="form-span">{i18n.t("phone")}:</span>&nbsp;
                  <span className="form-data">{this.state.personalDataForm.phone}</span>
                </div>
              </div>
              {/* End container */}
              <hr className='divider'/>

              {/*<h4>{i18n.t("activity")}</h4>*/}
              {/*<div className="container">*/}
              {/*  /!* <div className="w-50">*/}
              {/*    <span className="form-span">{i18n.t('balance')}:</span>&nbsp;*/}
              {/*    <span className="form-data">{storage.me.current_balance} €</span>*/}
              {/*  </div> *!/*/}


              {/*</div>*/}
              <h4>{i18n.t("user_brand_title")}</h4>
              <p>{i18n.t('user_brand_info')}</p>
              <div className='container'>
                <div className="w-50">
                  <span className="form-span">{i18n.t('brand_name')}:</span>&nbsp;
                  <input className="form-data form-control pe-4" onChange={(e) => this.setState({
                    ...this.state,
                    user_brand: {
                      ...this.state.user_brand,
                      brand_name: e.target.value,
                    }
                  })} value={this.state.user_brand.brand_name}/>
                </div>
                <div className="w-50">
                  <span className="form-span">{i18n.t('email')}:</span>&nbsp;
                  <input className="form-data form-control pe-4" onChange={(e) => this.setState({
                    ...this.state,
                    user_brand: {
                      ...this.state.user_brand,
                      email: e.target.value,
                    }
                  })} value={this.state.user_brand.email}/>
                </div>

                <div className="w-50">
                  <span className="form-span">{i18n.t('address')}:</span>&nbsp;
                  <input className="form-data form-control pe-4" onChange={(e) => this.setState({
                    ...this.state,
                    user_brand: {
                      ...this.state.user_brand,
                      address: e.target.value,
                    }
                  })} value={this.state.user_brand.address}/>
                </div>
                <div className="w-50">
                  <span className="form-span">{i18n.t('city')}:</span>&nbsp;
                  <input className="form-data form-control pe-4" onChange={(e) => this.setState({
                    ...this.state,
                    user_brand: {
                      ...this.state.user_brand,
                      city: e.target.value,
                    }
                  })} value={this.state.user_brand.city}/>
                </div>

                <div className="w-50">
                  <span className="form-span">{i18n.t('zip')}:</span>&nbsp;
                  <input className="form-data form-control pe-4" onChange={(e) => this.setState({
                    ...this.state,
                    user_brand: {
                      ...this.state.user_brand,
                      zip: e.target.value,
                    }
                  })} value={this.state.user_brand.zip}/>
                </div>
                <div className="w-50">
                  <span className="form-span">{i18n.t('gemi')}:</span>&nbsp;
                  <input className="form-data form-control pe-4" onChange={(e) => this.setState({
                    ...this.state,
                    user_brand: {
                      ...this.state.user_brand,
                      gemi: e.target.value,
                    }
                  })} value={this.state.user_brand.gemi}/>
                </div>

                <div className="w-50">
                  <span className="form-span">{i18n.t('phone')}:</span>&nbsp;
                  <input className="form-data form-control pe-4" onChange={(e) => this.setState({
                    ...this.state,
                    user_brand: {
                      ...this.state.user_brand,
                      phone: e.target.value,
                    }
                  })} value={this.state.user_brand.phone}/>
                </div>

                <div className="w-100">
                  <span className="form-span d-block">{i18n.t('logo')}:</span>&nbsp;
                  <p>{i18n.t('logo_info')}</p>
                  <input type="file" accept="image/*" onChange={this.handleFileChange}/>
                  {
                      this.state.user_brand.logo_preview &&
                      <div>
                        <img src={this.state.user_brand.logo_preview}
                             style={{width: '600px', height: '150px', objectFit: 'contain'}}/>
                        <br/>
                        <button className='btn btn-close' onClick={this.removeLogo}>{i18n.t('clear_logo')}</button>
                      </div>
                  }
                </div>

                <div className="w-100">
                  <button className='btn btn-save' onClick={this.setUserBrand}>{i18n.t('save_changes')}</button>
                </div>
              </div>
              {/* End container */}
              {/* <hr className='divider'/> */}


            </div>
            {/* End tab 1 personal data*/}

            <div className={`${this.state.activeTab !== 'change_password' ? 'hidden' : ''}`}>
              <h4>{i18n.t("change_password")}</h4>
              <div className="container">
                <div className="w-100">
                  <label className="form-label">
                    <span>{i18n.t("old_password")}</span>
                    <span className="text-danger">*</span>
                  </label>

                  <input
                      type="text"
                      className="form-control"
                      // autoComplete="new-password"
                      value={this.state.passwordForm.oldPassword}
                      onChange={(e) => this.onPasswordChange(e, 'oldPassword')}
                  />
                </div>

                <div className="w-50 col-left">
                  <label className="form-label">{i18n.t("new_password")}<span className="text-danger">*</span></label>
                  <input
                      type="text"
                      className="form-control"
                      // autoComplete="new-password"
                      value={this.state.passwordForm.newPassword}
                      onChange={(e) => this.onPasswordChange(e, 'newPassword')}
                  />
                </div>

                <div className="w-50 col-right">
                  <label className="form-label">{i18n.t("confirm_password")}</label>
                  <input
                      type="text"
                      className="form-control"
                      // autoComplete="new-password"
                      value={this.state.passwordForm.confirmPassword}
                      onChange={(e) => this.onPasswordChange(e, 'confirmPassword')}
                  />
                </div>

                <div>
                  <div
                      className={`alert ${this.state.messageType} ${this.state.messageStatus === false ? 'hidden' : ''}`}>
                    {this.state.message}
                  </div>
                  <input
                      type="submit"
                      className="btn btn-outline"
                      value="Save changes"
                      onClick={this.submitPassword}
                  />
                </div>

              </div>
            </div>

            <div className={`billing-address ${this.state.activeTab !== 'address' ? 'hidden' : ''}`}>
              <div className="mb-20">
                <h4 className="inline">{i18n.t("addresses")}</h4>
                <span
                    className="add-address"
                    onClick={() => this.setState({...this.setState, modalStatus: true})}
                >
                <FontAwesomeIcon icon={faTimesCircle}/>
                <span>{i18n.t("add_new_address")}</span>
              </span>
                <Modal
                    modalTitle="Προσθήκη νέας διεύθυνσης"
                    showModal={this.state.modalStatus}
                    onClose={() => this.setState({...this.state, modalStatus: false})}
                >


                  <div className="container">
                    <div className="w-50 col-left">
                      <label className="form-label col-left">
                        <span>{i18n.t("address")}</span>
                        <span className="text-danger">*</span>
                      </label>
                      <input
                          type="text"
                          className="form-control"
                          onChange={(e) => {
                            this.onInputChange(e, 'address')
                          }}
                      />
                    </div>

                    <div className="w-50 col-right">
                      <label className="form-label col-right">
                        <span>{i18n.t("city")}</span>
                        <span className="text-danger">*</span>
                      </label>
                      <input
                          type="text"
                          className="form-control"
                          onChange={(e) => {
                            this.onInputChange(e, 'city')
                          }}
                      />
                    </div>

                    <div className="w-50 col-left">
                      <label className="form-label col-left">
                        <span>{i18n.t("region")}</span>
                        <span className="text-danger">*</span>
                      </label>
                      {
                        this.state.regions && this.state.addressDataForm.country === 'ΕΛΛΑΣ' ?
                            <select className="address-select"
                                    id="region-greece"
                                    value={this.state.addressDataForm.country}
                                    onChange={(e) => {
                                      this.onInputChange(e, 'region');
                                    }}
                            >
                              {
                                this.state.regions.map((d, i) => {
                                  return <option key={i} value={d.id}>{d.description}</option>
                                })
                              }
                            </select>
                            :
                            <input
                                type="text"
                                id="region-foreign"
                                className="form-control"
                                onChange={(e) => {
                                  this.onInputChange(e, 'region')
                                }}
                            />
                      }
                    </div>


                    <div className="w-50 col-right">
                      <label className="form-label">
                        <span>{i18n.t("zip")}</span>
                        <span className="text-danger">*</span>
                      </label>
                      <input
                          type="text"
                          className="form-control"
                          onChange={(e) => {
                            this.onInputChange(e, 'zip')
                          }}
                      />
                    </div>

                    <div className="w-50 col-left">
                      <label className="form-label">
                        <span>{i18n.t("country")}</span>
                        <span className="text-danger">*</span>
                      </label>
                      {
                          this.state.countries &&
                          <select className="address-select"
                                  onChange={(e) => {
                                    this.onInputChange(e, 'country');
                                  }}
                          >
                            {
                              this.state.countries.map((d, i) => {
                                return <option key={i} value={d.id}>{d.name}</option>
                              })
                            }
                          </select>
                      }
                    </div>

                    <div className="w-50 col-right">
                      <label className="form-label">
                        <span>{i18n.t("phone")}</span>
                        <span className="text-danger">*</span>
                      </label>
                      <input
                          type="text"
                          className="form-control"
                          onChange={(e) => {
                            this.onInputChange(e, 'phone')
                          }}
                      />
                    </div>

                    <div className="w-100">
                      <label className="form-label">
                        <span>{i18n.t("notes")}</span>
                      </label>
                      <textarea
                          className="form-control"
                          value={this.state.addressDataForm.notes}
                          onChange={(e) => {
                            this.onInputChange(e, 'notes')
                          }}
                      >
                        {this.state.addressDataForm.notes}
                      </textarea>
                    </div>

                  </div>

                  <div className="mb-20">
                    <input
                        type="submit"
                        className="btn btn-outline"
                        value="Προσθήκη"
                        onClick={this.addAddress}
                    />
                  </div>
                </Modal>
              </div>
              <div className="container billing">
                {
                  this.state.addressData.map((address) => {
                    return (
                        <div className={`col-50`}>
                          <div className="card-header">
                        <span>
                          {/* Default address icon */}
                          <span className="tooltip">
                            <span
                                className="delete-icon tooltip"
                            >
                              <span
                                  className={this.state.default_address === address.id ? 'active-address' : ''}></span>
                              <span className="tooltiptext">{i18n.t("billing_address")}</span>
                            </span>
                          </span>

                          {address.address} {address.number}
                        </span>

                            {
                                this.state.default_address !== address.id &&
                                <span>
                            <span className="edit tooltip">
                                <span
                                    className="delete-icon tooltip"
                                    onClick={() => this.showEditModal(address.id)}
                                >
                                  <FontAwesomeIcon icon={faEye}/>
                                  <span className="tooltiptext">{i18n.t("edit")}</span>
                                </span>
                            </span>

                                  {/* delete address */}
                                  <span className="tooltip">
                              <span
                                  className="delete-icon tooltip"
                                  // onClick={ () => this.setState({...this.state, showDeleteAddressModal: true}) }
                                  onClick={() => this.showDeleteAddressModal(address.id)}
                              >
                                <FontAwesomeIcon icon={faTimesCircle}/>
                                <span className="tooltiptext">{i18n.t("delete")}</span>
                              </span>
                            </span>
                          </span>
                            }


                            {/* Start show New Address Added Modal */}
                            <Modal
                                modalTitle={i18n.t("new_address_inserted")}
                                showModal={this.state.showNewAddressAddedModal}
                                onClose={() => this.setState({...this.state, showNewAddressAddedModal: false})}
                            >
                              {/* <p>Επιβεβαίωση διαγραφής</p>*/}

                              <div className="mb-20 address-added">
                                <button
                                    className="btn btn-outline"
                                    value="OK"
                                    onClick={() => this.setState({...this.state, showNewAddressAddedModal: false})}

                                >OK
                                </button>
                              </div>
                            </Modal>
                            {/* End show New Address Added Modal */}


                          </div>
                          <div className="card-body">
                            <div className="d-flex flex-direction-column">
                              <span>{i18n.t("address")}: {address.address}</span>
                              <span>{i18n.t("region")}: {address.region}</span>
                              <span>{i18n.t("zip")}: {address.zip}</span>
                              <span>{i18n.t("city")}: {address.city}</span>
                              <span>{i18n.t("country")}: {address.country}</span>
                              <span>{i18n.t("phone")}: {address.phone}</span>
                              <span>{i18n.t("notes")}: {address.notes}</span>
                            </div>
                          </div>
                        </div>
                    );
                  })
                } {/* End map get addresses*/}
              </div>
            </div>

            {/* Start delete address modal */}
            <Modal
                modalTitle={`${i18n.t("delete_address_prompt")} ${this.state.deleteAddress.address}; `}
                showModal={this.state.showDeleteAddressModal}
                onClose={() => this.setState({...this.state, showDeleteAddressModal: false})}
            >
              <p>{i18n.t("confirm_delete")}</p>

              <div className="mb-20">
                <input
                    type="submit"
                    className="btn btn-outline"
                    value={i18n.t("delete")}
                    onClick={() => {
                      this.deleteAddress(this.state.deleteAddress.id)
                    }}
                />
              </div>
            </Modal>
            {/* End delete address modal */}

            {/* =========================================================   tab 4 =========================================================*/}
            <div className={`orders ${this.state.activeTab !== 'orders' ? 'hidden' : ''}`}>
              <h4>My Orders</h4>
              <table className="table table-bordered align-middle">
                <thead>
                <tr className="text-uppercase text-nowrap">
                  <th className="text-dark text-center fw-500 image">Α/Α</th>
                  <th className="text-dark text-center fw-500">{i18n.t("date")}</th>
                  <th className="text-dark text-center fw-500">{i18n.t("price")}</th>
                  <th className="text-dark text-center fw-500">Προβολή</th>
                </tr>
                </thead>
                <tbody>
                {
                    this.state.ordersData.orders &&
                    this.state.ordersData.orders.map((order, i) => {
                      return (
                          <tr>
                            <td>{order.id}</td>
                            <td>{order.date}</td>
                            <td>{utils.round(parseFloat(order.payment_method_cost) + parseFloat(order.shipping_method_cost) + parseFloat(order.total_cost)).toFixed(2)} €</td>
                            <td style={{textAlign: 'center'}}>
                              <button
                                  className="btn btn-primary"
                                  style={{margin: 'auto'}}
                                  onClick={() => this.showOrder(i)}
                              >
                                <FontAwesomeIcon icon={faEye}/>
                              </button>
                            </td>
                          </tr>
                      )
                    })
                }
                </tbody>
              </table>
              {
                  this.state.ordersData.total && this.state.ordersData.total > 0 &&
                  <div className={`pagination ${!this.state.orders_page ? 'hidden' : ''}`}>
                    <div onClick={() => this.changeOrdersPage(Math.max(1, this.state.orders_page - 1))}><FontAwesomeIcon
                        icon={faAngleLeft}/></div>
                    {
                      this.state.orders_page > 3
                          ? <div onClick={() => this.changeOrdersPage(1)}>1</div>
                          : ''
                    }
                    {
                      this.state.orders_page > 2
                          ? <div
                              onClick={() => this.changeOrdersPage(this.state.orders_page - 2)}>{this.state.orders_page - 2}</div>
                          : ''
                    }
                    {
                      this.state.orders_page > 1
                          ? <div
                              onClick={() => this.changeOrdersPage(this.state.orders_page - 1)}>{this.state.orders_page - 1}</div>
                          : ''
                    }
                    {
                      this.state.ordersPages > 0
                          ? <div className="active">{this.state.orders_page}</div>
                          : ''
                    }
                    {
                      parseInt(this.state.orders_page) + 1 <= this.state.ordersPages
                          ? <div
                              onClick={() => this.changeOrdersPage(parseInt(this.state.orders_page) + 1)}>{parseInt(this.state.orders_page) + 1}</div>
                          : ''
                    }
                    {
                      parseInt(this.state.orders_page) + 2 <= this.state.ordersPages
                          ? <div
                              onClick={() => this.changeOrdersPage(parseInt(this.state.orders_page) + 2)}>{parseInt(this.state.orders_page) + 2}</div>
                          : ''
                    }
                    {
                      parseInt(this.state.orders_page) + 3 <= this.state.ordersPages
                          ? <div
                              onClick={() => this.changeOrdersPage(parseInt(this.state.orders_page) + 3)}>{parseInt(this.state.orders_page) + 3}</div>
                          : ''
                    }

                    <div
                        onClick={() => this.changeOrdersPage(Math.min(this.state.ordersPages, parseInt(this.state.orders_page) + 1))}>
                      <FontAwesomeIcon icon={faAngleRight}/></div>
                  </div>
              }
            </div>

            <div className={`tab-personal-detail ${this.state.activeTab !== 'tab_datafeed' ? 'hidden' : ''}`}>
              <h4>{i18n.t("datafeed_information")}</h4>
              <div className="container">
                <div className="w-100">
                  <h4>XML</h4>
                  <p>{i18n.t("xml_description")}
                  </p>
                  <br/>
                  <i>{i18n.t("xml_warning")}</i>
                  <br/>
                  <br/>
                  <span>{i18n.t("xml_note_header")}</span>
                  <i>{i18n.t("xml_note")}</i>
                </div>
                <div className="w-100">
                  <span className="form-span">Datafeed link:</span>&nbsp;
                  <span className="form-data"
                        style={{wordBreak: "break-all"}}><i>https://present-team.eu/datafeed/products_feed/{this.state.xml_token}</i></span>
                </div>


              </div>
              {/* End container */}
              <hr className='divider'/>

              <h4>API</h4>
              <div className="container">
                <div className="w-100">
                  <p>{i18n.t("api_description")}
                  </p>
                  <br/>
                  <p>{i18n.t("api_link_prompt")} <a href="https://present-team.eu/datafeed/documentation"
                                                    target="_blank">{i18n.t("here")}</a></p>
                  <br/>
                  <p>{i18n.t("api_contact_prompt")} <a href="mailto:marketing@present-team.eu"
                                                       target="_blank">marketing@present-team.eu</a></p>
                </div>


              </div>
              {/* End container */}


            </div>
            <div className={`orders ${this.state.activeTab !== 'access_tokens' ? 'hidden' : ''}`}>
              <h4>{i18n.t("seller_menu_access_tokens")}</h4>
              <button className='btn btn-primary d-flex align-items-center' onClick={() => {
                this.setState({
                  ...this.state,
                  showLinkModal: true,
                })
              }}> <FontAwesomeIcon className="icon-left" icon={faPlus}/>&nbsp;{i18n.t('new_access_token')}</button>
              <table className="table table-bordered align-middle">
                <thead>
                <tr className="text-uppercase text-nowrap">
                  <th className="text-dark text-center fw-500">{i18n.t("link")}</th>
                  <th className="text-dark text-center fw-500">{i18n.t("date")}</th>
                  <th className="text-dark text-center fw-500">{i18n.t("visited_count")}</th>
                  <th></th>
                </tr>
                </thead>
                <tbody>
                {
                    this.state.access_links &&
                    this.state.access_links.map((link, i) => {
                      return (
                          <tr>
                            <td>
                              <div className='d-flex align-items-center justify-content-between'>
                                <span className='fs-sm'>https://cotton-plus.eu?user-access-token={link.token}</span>
                                <button className='btn btn-outline-primary' onClick={() => this.copyLink(`https://cotton-plus.eu?user-access-token=${link.token}`)}><FontAwesomeIcon icon={faCopy}/></button>
                              </div>
                            </td>
                            <td>{link.expiration_date}</td>
                            <td>{link.visitors}</td>
                            <td><button className='btn btn-danger' onClick={() => {this.deleteLink(link.token)}}><FontAwesomeIcon icon={faTrash}/></button></td>
                          </tr>
                      )
                    })
                }
                </tbody>
              </table>
            </div>
          </div>
          {
              this.state.showOrderModal &&

              <Modal
                  modalTitle={`${i18n.t("order")} #${this.state.orderModalData.id}`}
                  showModal={this.state.showOrderModal}
                  onClose={() => this.setState({...this.state, showOrderModal: false})}
                  modalLg={true}
                  titleLg={true}
              >
                <div className="container">
                  <div className='order-lines'>
                    {
                      this.state.orderModalData.product_lines.map(p => {
                        return <div className='order-line'>
                          <div className='title'>
                            <Link
                                to={`/products/${p.product_id}`}>#{p.product_data.code} {i18n.get_title(p.product_data)}</Link>
                            <div style={{marginTop: '10px'}}>
                              {
                                p.product_data.selectedVariant.attributes.map(a => {
                                  return <span style={{marginRight: '15px'}}>
                              {i18n.get_attribute_category(a)}: {a.value}
                            </span>
                                })
                              }
                            </div>
                          </div>
                          <div className='qtys'>
                            {
                              p.discount_percent > 0 ?
                                  <div>
                                    <div className='old-price'>{i18n.t("from")} {utils.round(p.price).toFixed(2)} €</div>
                                    {
                                      p.discount_percent == 100 ?
                                          <div className='price price-gift'> {i18n.t("gift")}</div>
                                          :
                                          <div className='price'> {utils.round(p.price - (p.price * (p.discount_percent / 100))).toFixed(2)} €</div>
                                    }

                                    <div className='qty'> {(p.qty)} {i18n.t("pcs")}</div>
                                  </div>
                                  :
                                  <div>
                                    <div className='price'> {utils.round(p.price).toFixed(2)} €</div>
                                    <div className='qty'> {(p.qty)} {i18n.t("pcs")}</div>
                                  </div>
                            }
                          </div>
                        </div>
                      })
                    }
                  </div>
                  <div className='order-data'>
                    <div className="order-address">
                      <h4>{i18n.t("address_data")}</h4>
                      <div><span>{i18n.t("address")}:</span> <span>{this.state.orderModalData.shipping_address.address}</span></div>
                      <div><span>{i18n.t("city")}:</span> <span>{this.state.orderModalData.shipping_address.city}</span></div>
                      <div><span>{i18n.t("zip")}:</span> <span>{this.state.orderModalData.shipping_address.zip}</span></div>
                      <div><span>{i18n.t("country")}:</span> <span>{this.state.orderModalData.shipping_address.country}</span></div>
                    </div>
                    <div className="order-address" style={{paddingRight: 0, width: '50%'}}>
                      <h4>{i18n.t("order_data")}</h4>
                      <div><span>{i18n.t("shipping_method")}:</span> <span>{i18n.t(this.state.orderModalData.shipping_method)}</span></div>
                      <div><span>{i18n.t("payment_method")}:</span> <span>{i18n.t(this.state.orderModalData.payment_method)}</span></div>
                      {
                          this.state.orderModalData.shipping_method_cost > 0 &&
                          <div><span>{i18n.t("shipping_method_cost")}:</span> <span>{utils.round(this.state.orderModalData.shipping_method_cost).toFixed(2)} €</span></div>
                      }
                      {
                          this.state.orderModalData.payment_method_cost > 0 &&
                          <div><span>{i18n.t("payment_method_cost")}:</span> <span>{utils.round(this.state.orderModalData.payment_method_cost).toFixed(2)} €</span></div>
                      }
                      {
                          this.state.orderModalData.coupon_data &&
                          <div className="coupon-banner"><span>{i18n.t("coupon")}:</span> <span>-{utils.round(this.state.orderModalData.coupon_data.discount_price).toFixed(2)} €</span></div>
                      }
                      <div><span style={{fontWeight: 600}}>{i18n.t("total")}:</span> <span style={{fontWeight: 600}}>{utils.round(parseFloat(this.state.orderModalData.payment_method_cost) + parseFloat(this.state.orderModalData.shipping_method_cost) + parseFloat(this.state.orderModalData.total_cost)).toFixed(2)} €</span></div>
                    </div>
                  </div>
                </div>


              </Modal>
          }
          {/* Start update address modal */}
          <Modal
              modalTitle={i18n.t("edit_address")}
              showModal={this.state.showUpdateModal}
              onClose={() => this.setState({ ...this.state, showUpdateModal: false })}
          >


            <div className="container">
              <div className="w-50 col-left">
                <label className="form-label col-left">
                  <span>{i18n.t("address")}</span>
                  <span className="text-danger">*</span>
                </label>
                <input
                    type="text"
                    className="form-control"
                    value={this.state.editAddress.address}
                    onChange={(e) => { this.onInputChangeUpdateAddress(e, 'address')  }}
                />
              </div>
              <div className="w-50 col-right">
                <label className="form-label col-right">
                  <span>{i18n.t("city")}</span>
                  <span className="text-danger">*</span>
                </label>
                <input
                    type="text"
                    className="form-control"
                    value={this.state.editAddress.city}
                    onChange={ (e) => { this.onInputChangeUpdateAddress(e, 'city') }}
                />
              </div>

              <div className="w-50 col-left">
                <label className="form-label col-left">
                  <span>{i18n.t("region")}</span>
                  <span className="text-danger">*</span>
                </label>
                {
                  this.state.regions && this.state.editAddress.country === 'ΕΛΛΑΣ' ?
                      <select className="address-select"
                              id="region-greece"
                              value={this.state.editAddress.region}
                              onChange={(e) => {this.onInputChangeUpdateAddress(e, 'region'); } }
                      >
                        {
                          this.state.regions.map( (d, i) => {
                            return <option key={i} value={d.id}>{d.description}</option>
                          })
                        }
                      </select>
                      :
                      <input
                          type="text"
                          id="region-foreign"
                          className="form-control"
                          value={this.state.editAddress.region}
                          onChange={ (e) => { this.onInputChangeUpdateAddress(e, 'region') }}
                      />
                }
              </div>



              <div className="w-50 col-right">
                <label className="form-label">
                  <span>{i18n.t("zip")}</span>
                  <span className="text-danger">*</span>
                </label>
                <input
                    type="text"
                    className="form-control"
                    value={this.state.editAddress.zip}
                    onChange={ (e) => { this.onInputChangeUpdateAddress(e, 'zip') }}
                />
              </div>

              <div className="w-50 col-left">
                <label className="form-label">
                  <span>{i18n.t("country")}</span>
                  <span className="text-danger">*</span>
                </label>
                {
                    this.state.countries &&
                    <select  className="address-select"
                             value={this.state.editAddress.country}
                             onChange={(e) => {this.onInputChangeUpdateAddress(e, 'country'); } }
                    >
                      {
                        this.state.countries.map( (d, i) => {
                          return <option key={i} value={d.id}>{d.name}</option>
                        })
                      }
                    </select>
                }
              </div>

              <div className="w-50 col-right">
                <label className="form-label">
                  <span>{i18n.t("phone")}</span>
                  <span className="text-danger">*</span>
                </label>
                <input
                    type="text"
                    className="form-control"
                    value={this.state.editAddress.phone}
                    onChange={ (e) => { this.onInputChangeUpdateAddress(e, 'phone') }}
                />
              </div>

              <div className="w-100">
                <label className="form-label">
                  <span>{i18n.t("notes")}</span>
                </label>
                <textarea
                    className="form-control"
                    value={this.state.editAddress.notes}
                    onChange={ (e) => { this.onInputChangeUpdateAddress(e, 'notes') }}
                >
                  {this.state.editAddress.notes}
                </textarea>
              </div>

              <div className="w-100">
                {/* <span>
                {JSON.stringify(this.state.editDefaultAddress)}
                <input type="checkbox"
                  checked = {this.state.defaultAddress === this.state.editAddress.id}
                  onChange = { (e) => {this.handleCheckBox(e.target.checked, 'editDefaultAddress')}}
                />
                {/* className={`${this.state.isCheckedAddressBox === true ? '' : 'hidden'}`} *
                <label className="form-label default-address">
                  <span>Προκαθορισμένη διεύθυνση</span>
                </label>
              </span> */}
              </div>

            </div> {/* End container */}

            <div className="mb-20">
              <input
                  type="submit"
                  className="btn btn-outline"
                  value="Αποθήκευση"
                  onClick={this.updateAddress}
              />
            </div>
          </Modal>
          {/* End update address modal */}

          {/* Start insert access link modal */}
          <Modal
              modalTitle={i18n.t("new_access_token")}
              showModal={this.state.showLinkModal}
              onClose={() => this.setState({ ...this.state, showLinkModal: false })}
          >

            <div className="container">
              <div className="w-50 col-left">
                <label className="form-label col-left">
                  <span>{i18n.t("expiration_date")}</span>
                  <span className="text-danger">*</span>
                </label>
                <DatePicker locale="en-GB" minDate={new Date()} value={this.state.expiration_date} onChange={(date) => this.setExpirationDate(date)} />
              </div>

            </div> {/* End container */}

            <div className="mb-20">
              <input
                  type="submit"
                  className="btn btn-outline"
                  value="Αποθήκευση"
                  onClick={this.saveAccessToken}
              />
            </div>
          </Modal>
          {/* End insert access link modal */}

        </div>
    );
  }
}

export default withRouter(AccountPage);